body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Ensure sections have enough padding to not be covered by the fixed header */
section {
  padding-top: 100px; /* Adjust as needed */
}

/* General navigation styling */
.navmenu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navmenu ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.navmenu ul li {
  margin: 0 15px;
}

.navmenu ul li a {
  text-decoration: none;
  color: #fff;
}

.mobile-nav-toggle {
  display: none;
  cursor: pointer;
}

/* Media query for mobile devices */
@media (max-width: 991px) {
  .mobile-nav-toggle {
      height: 50%;
    display: block;
  }

  .navmenu ul {
    display: block;

    background-color: #333;
    width: 25%;
    height: 50%;

  }

  .navmenu ul li {
    margin: 10px 0;
    text-align: center;
  }

  .navmenu.open ul {
    display: flex;
  }
}
